const logingx = ()=>{
    try {
        jQuery(document).ready(function($) {
   
            $(".checkmails").click(function(){
    
              var input_value = $('input[name=email]').val();
                $.post( validateEmail.ajaxurl, { action:'validate_email', email:input_value }, function(data) {
                //$('.message').html(data);
                console.log(data)
                if(data==1){
                  console.log('si existe mail')
                }else{
                  console.log('No existe mail')
                  
                    if (input_value) {
                        console.log('Email ingresado:', input_value);
                        //window.location.href = 'register/?email='+encodeURIComponent(input_value);
                        var siteurl = objecto_name.siteurl;
                        //console.log(siteurl)
                        var redirecto = siteurl+'/register/?email='+encodeURIComponent(input_value)
                        window.location.href = redirecto;
                        //console.log('register/?email='+encodeURIComponent(input_value))
                    } else {
                        console.error('El campo de email no fue encontrado');
                    }
                }
              });
    
            });
        });
    } catch (error) {

    }
    
}
export{logingx}

const logeoGX = ()=>{
  try {
    jQuery(document).ready(function($) {
        $('#login-form-password').on('submit', function(e) {
        
            e.preventDefault(); // Prevenir el envío tradicional del formulario
  
            var loginData = {
                'action': 'custom_login_action', // El nombre de la acción
                'log': $('#user_login').val(), // Usuario
                'pwd': $('#user_pass').val(), // Contraseña
                'login_custom_nonce': $('#login_custom_nonce').val(), // Nonce
            };

            const formlogin = $('#login-form-password').data('tokenlog');
            console.log("Token obtenido: ", formlogin);

            if (formlogin == '1asd123as1asdq') {
                console.log("Datos de login: ", loginData);
                console.log("URL de AJAX: ", ajax_objectlog.ajax_url);

                // Enviar la solicitud AJAX
                $.post(ajax_objectlog.ajax_url, loginData, function(response) {
                    console.log("Respuesta recibida:", response); // Verificar la respuesta del servidor
                    if (response.success) {
                        window.location.href = response.data.redirect; // Redirigir si el login es exitoso
                    } else {
                        // Mostrar error en el formulario
                        var errorbox = $('#errordiv');
                        errorbox.html('<p>' + response.data.message + '</p>');
                        errorbox.removeClass("hiddebox");
                    }
                }, 'json');
            } else {
                console.log("Token inválido. No se envió la solicitud AJAX.");
            }
          
        });
    }); 
  } catch (error) {

  }
  
}
export{logeoGX}


//*-********************************************************************************
const interacciones = () => {
  try {
      jQuery(document).ready(function($) {
          const userPassInput = document.getElementById("user_pass");
          if (userPassInput) {  // Verifica si el elemento existe
              userPassInput.addEventListener("input", function() {
                  var errorbox = document.getElementById('errordiv');
                  errorbox.innerHTML = '';
                  errorbox.classList.add("hiddebox");  
              });
          } else {
              console.warn('El elemento con ID "user_pass" no existe en el DOM');
          }
      });
  } catch (error) {
      console.error('Error en interacciones:', error);
  }
}

export { interacciones };


/**/
//*-********************************************************************************
const interaccionesregister = ()=>{
  try {
    jQuery(document).ready(function($) {

      var name = document.getElementById("name");
      var name_menor = document.getElementById("name-menor");
      if (name && name_menor) {
          name.addEventListener("input", function() {
              name_menor.value = name.value;
          });
      }
  
      var last_name = document.getElementById("last_name");
      var last_name_menor = document.getElementById("last_name-menor");
      if (last_name && last_name_menor) {
          last_name.addEventListener("input", function() {
              last_name_menor.value = last_name.value;
          });
      }
  
      var day = document.getElementById("day");
      var day_menor = document.getElementById("day-menor");
      if (day && day_menor) {
          day.addEventListener("input", function() {
              day_menor.value = day.value;
          });
      }
  
      var month = document.getElementById("month");
      var month_menor = document.getElementById("month-menor");
      if (month && month_menor) {
          month.addEventListener("input", function() {
              month_menor.value = month.value;
          });
      }
  
      var year = document.getElementById("year");
      var year_menor = document.getElementById("year-menor");
      if (year && year_menor) {
          year.addEventListener("input", function() {
              year_menor.value = year.value;
          });
      }
  
      var country = document.getElementById("country");
      var country_menor = document.getElementById("country-menor");
      if (country && country_menor) {
          country.addEventListener("input", function() {
              country_menor.value = country.value;
          });
      }
  
  }); 
} catch (error) {

}

}
export{interaccionesregister}

/**/

const registerGX = ()=>{
  try {
    jQuery(document).ready(function($) {
      $('#register-form').on('submit', function(e) {
          e.preventDefault(); // Evitar la recarga de la página
          
          // Serializar los datos del formulario
          var formData = $(this).serialize();
          //console.log('formdata:'+formData)
          
          // Asegurarse de que el objeto `ajax_object` está disponible
          if (typeof ajax_object === 'undefined') {
            console.error('ajax_object no está definido. Verifica wp_localize_script.');
            return;
          }
          // Realizar la petición AJAX}
          setTimeout(()=>{
            const body = document.body;
            if(e.target.dataset.token == '1asd123as1asdqeq'&& !body.classList.contains("es-menor")){
              $.ajax({
                url: ajax_object.ajaxurl, // Correcto uso de ajaxurl
                type: 'POST',
                data: formData + '&action=custom_register_user&register_custom_nonce=' + ajax_object.nonce, // Correcto uso del nonce
                success: function(response) {
                    if (response.success) {
                        console.log(response.data);
                        var siteurl = objecto_name.siteurl;
                        window.location.href = siteurl+'/login';// Redirigir si el login es exitoso
                    } else {
                        console.log('Error: ' + response.data);
                    }
                },
                error: function(xhr, status, error) {
                    console.error('Error en la solicitud AJAX:', error);
                    console.log('Hubo un error al procesar la solicitud.');
                }
              });
            }
          }, 500)
      });
    }); 
  } catch (error) {
    
  }
}
export{registerGX}
/**/
/** */

const registertutorGX = ()=>{
  try {
    jQuery(document).ready(function($) {
      $('#register-form-tutor').on('submit', function(e) {
          e.preventDefault();
  
          var formData = {
              action: 'register_tutor',
              email_menor: $('#email-menor').val(),
              name_menor: $('#name-menor').val(),
              last_name_menor: $('#last_name-menor').val(),
              day_menor: $('#day-menor').val(),
              month_menor: $('#month-menor').val(),
              year_menor: $('#year-menor').val(),
              country_menor: $('#country-menor').val(),
              tutor_name: $('#tutor_name').val(),
              tutor_last_name: $('#tutor_last_name').val(),
              tutor_email: $('#tutor_email').val(),
              tutor_document_number: $('#tutor_document_number').val(),
              tutor_day: $('#tutor_day').val(),
              tutor_month: $('#tutor_month').val(),
              tutor_year: $('#tutor_year').val(),
              tutor_country: $('#tutor_country').val()
          };

          $.post(gxData.ajaxurl, formData, function(response) {
            if (response.success) {
                alert('Registro exitoso');
            } else {
                alert('Error en el registro');
            }
        });

      });
    });
  
  } catch (error) {
    
  }
}
export{registertutorGX}

/** Actualizar datos en el landing de usuario */

const actualizadatosformuno = ()=>{
  try {
    jQuery(document).ready(function($) {
      $('#update-user').on('click', function(e) {
          e.preventDefault();
          
          var formData = {
              action: 'update_user_profile',
              name: $('#name').val(),
              last_name: $('#last_name').val(),
              email: $('#email').val(),
              day: $('#day').val(),
              month: $('#month').val(),
              year: $('#year').val(),
              country: $('#country').val(),
              security: gxData.nonce // Asegúrate de agregar un nonce en el localizador de datos
          };
    
          $.post(gxData.ajaxurl, formData, function(response) {
              if (response.success) {
                  alert('Datos actualizados correctamente');
              } else {
                  alert('Error al actualizar los datos');
              }
          });
      });
    });
  
  } catch (error) {
    
  }
}
export{actualizadatosformuno}

const registerLinksGX = () => {
  jQuery(document).ready(function($) {
    $('#update-links').on('click', function(e) {
        e.preventDefault();

        // Obtiene los valores de los campos de entrada
        let youtube = $('#youtube').val();
        let discord = $('#Discord').val();
        let twitch = $('#Twitch').val();
        let steam = $('#Steam').val();

        // Crea los datos para enviar con la acción correspondiente
        let formData = {
            action: 'update_social_links',  // Nombre de la acción para el servidor
            youtube: youtube,
            discord: discord,
            twitch: twitch,
            steam: steam,
        };

        // Realiza la solicitud AJAX a admin-ajax.php
        $.ajax({
            url: my_ajax_object.ajaxurl, // Usando la variable global ajaxurl proporcionada por WordPress
            type: 'POST',
            data: formData,
            success: function(response) {
                if (response.success) {
                    alert('Enlaces actualizados correctamente');
                } else {
                    alert('Error: ' + response.data); // Muestra el mensaje de error devuelto
                }
            },
            error: function(xhr, status, error) {
                console.log(error);
                alert('Hubo un error en la solicitud.');
            }
        });
    });
  });
}
export { registerLinksGX };

const actualizartutor = () => {
  jQuery(document).ready(function($) {
      $('#update-tutor').on('click', function(e) {
          e.preventDefault();

          const tutorData = {
              action: 'update_tutor_data',
              nonce: my_ajax_object.nonce,  // Asegúrate de que 'nonce' esté en los datos
              tutor_name: $('#tutor_name').val(),
              tutor_last_name: $('#tutor_last_name').val(),
              tutor_email: $('#tutor_email').val(),
              tutor_day: $('#tutor_day').val(),
              tutor_month: $('#tutor_month').val(),
              tutor_year: $('#tutor_year').val(),
              tutor_country: $('#tutor_country').val(),
          };

          console.log('tutordata: '+tutorData);

          $.ajax({
              url: my_ajax_object.ajax_url,
              type: 'POST',
              data: tutorData,
          })
          .done(function(response) {
              if (response.success) {
                  alert('Datos actualizados correctamente: ' + response.data);
              } else {
                  alert('Hubo un error al actualizar los datos: ' + response.data);
              }
          })
          .fail(function(jqXHR, textStatus, errorThrown) {
              alert('Hubo un error en la solicitud AJAX: ' + textStatus + ' - ' + errorThrown);
          });
      });
  });
};
export { actualizartutor };

const actualizarContrasena = () => {
  jQuery(document).ready(function($) {
      $('#update-passwd').on('click', function(e) {
          e.preventDefault();

          const newPassword = $('#new_password').val();
          const confirmPassword = $('#confirm_password').val();

          // Validar que las contraseñas coincidan
          if (newPassword !== confirmPassword) {
              alert('Las contraseñas no coinciden.');
              return;
          }

          // Datos que se enviarán en la solicitud AJAX
          const data = {
              action: 'update_password',
              new_password: newPassword,
              nonce: my_ajax_object.nonce // Incluye el nonce en la solicitud
          };

          $.ajax({
              url: my_ajax_object.ajax_url,
              type: 'POST',
              data: data,
              success: function(response) {
                  if (response.success) {
                      alert('Contraseña actualizada correctamente.');
                  } else {
                      alert('Error al actualizar la contraseña: ' + response.data);
                  }
              },
              error: function() {
                  alert('Hubo un error en la solicitud AJAX.');
              }
          });
      });
  });
};
export { actualizarContrasena };

const actualizaravatar = () => {
  document.getElementById('update-avatar-btn').addEventListener('click', function(e) {
    e.preventDefault();
    
    const formData = new FormData(document.getElementById('update-avatar-form'));
    formData.append('action', 'update_user_avatar');
    formData.append('nonce', updateAvatar.nonce);

    fetch(updateAvatar.ajax_url, {
        method: 'POST',
        body: formData,
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            alert('Imagen de perfil actualizada correctamente');
            location.reload();
        } else {
            alert('Error al actualizar la imagen: ' + data.data);
        }
    })
    .catch(error => console.error('Error:', error));
  });
};
export { actualizaravatar };

const interaccionesmodal = () => {
    try {
        jQuery(document).ready(function($) {
            $( ".editbtn" ).on( "click", function() {
                $( ".avatar-modal" ).addClass( "active" );
            } );

            $( ".closeavmodal" ).on( "click", function() {
                $( ".avatar-modal" ).removeClass( "active" );
            } );


            const form = document.getElementById('custom-billing-form');

            // Validar que el formulario existe
            if (!form) {
                console.error('Formulario "custom-billing-form" no encontrado.');
                return;
            }

                

                form.addEventListener('submit', async (e) => {
                    e.preventDefault();
                
                    const formData = new FormData(form);
                    console.log("ajax url: "+customBillingForm.ajaxurl);
                
                    console.log("data form"+[...formData.entries()]);
                    try {
                        const response = await fetch(customBillingForm.ajaxurl, {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'X-WP-Nonce': customBillingForm.nonce,
                            },
                        });
                
                        if (!response.ok) {
                            const errorText = await response.text();
                            console.error('Error en la solicitud:', response.status, response.statusText, errorText);
                            alert('Error al enviar los datos. Verifique la consola.');
                            return;
                        }
                
                        const data = await response.json();
                
                        if (data.success) {
                            alert(data.message || 'Datos actualizados correctamente.');
                        } else {
                            alert(data.message || 'Error al actualizar los datos.');
                        }
                    } catch (error) {
                        console.error('Error al enviar los datos:', error);
                        alert('Hubo un problema al enviar los datos. Intenta nuevamente.');
                    }
                });


        });
    } catch (error) {
        console.error('Error en interaccionesmodal:', error);
    }
  }
  
  export { interaccionesmodal };

  const actualizarBilling = () => {
    try {
        const form = document.getElementById('custom-billing-form');

        // Validar que el formulario existe
        if (!form) {
            console.error('Formulario "custom-billing-form" no encontrado.');
            return;
        }

        form.addEventListener('submit', async (e) => {
            e.preventDefault();

            const formData = new FormData(form);

            try {
                const response = await fetch(customBillingForm.ajaxurl, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'X-WP-Nonce': customBillingForm.nonce,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud: ${response.status}`);
                }

                const data = await response.json();

                if (data.success) {
                    alert(data.message || 'Datos actualizados correctamente.');
                } else {
                    alert(data.message || 'Error al actualizar los datos.');
                }
            } catch (error) {
                console.error('Error al enviar los datos:', error);
                alert('Hubo un problema al enviar los datos. Intenta nuevamente.');
            }
        });
    } catch (error) {
        console.error('Error en actualizarBilling:', error);
    }
};

export { actualizarBilling };

const fondoswallet = () => {
    try {
        jQuery(document).ready(function ($) {
            $('#add_funds_button').on('click', function (e) {
                e.preventDefault();
        
                const productId = $('#product_id').val();
                const errorMsg = $('.msg-error');
        
                if (!productId) {
                    errorMsg.text('Selecciona una opción').show();
                    return;
                }
        
                errorMsg.hide();
        
                // Llamada AJAX para agregar al carrito
                $.ajax({
                    url: wc_add_to_cart_params.ajax_url,
                    type: 'POST',
                    data: {
                        action: 'add_to_cart',
                        product_id: productId,
                    },
                    success: function (response) {
                        if (response.success) {
                            //alert('Producto añadido al carrito');
                            //location.reload();
                            window.location.href = '/carrito/';
                        } else {
                            alert('Error al añadir el producto');
                        }
                    },
                    error: function () {
                        alert('Error en la solicitud.');
                    },
                });
            });
        });
    } catch (error) {
        console.error('Error en fondoswallet:', error);
    }
};

export { fondoswallet };

const eliminardecarrito = () => {
    try {
        jQuery(document).ready(function ($) {
            $('body').on('click', '.remove-from-cart', function (e) {
                console.log("remover de carrito")
                e.preventDefault();
        
                const removeLink = $(this).attr('href');
        
                $.ajax({
                    url: removeLink,
                    type: 'GET',
                    success: function () {
                        // Recargar el contenido del carrito
                        location.reload();
                    },
                    error: function () {
                        alert('Error al eliminar el producto del carrito.');
                    },
                });
            });
        });
    } catch (error) {
        console.error('Error en eliminardecarrito:', error);
    }
};

export { eliminardecarrito };

