const EnableForm = (()=>{

    const EnableFormConfig = ()=>{
        const enableFormButton = document.querySelectorAll('.enable-form');

        enableFormButton.forEach((data)=>{
            data.addEventListener('click', (e)=>{
                const parent = e.target.closest('.enable-form-ctn');
                if(parent){
                    const form = parent.querySelector('.form-general');
                    form?.classList.toggle('custom-form--disable');
                    form?.classList.toggle('enable');
                }
            });
        });
    }

    return {
        handleEnableForm : function(){
            try { EnableFormConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleEnableForm = ()=>{
    EnableForm.handleEnableForm();
}

export { handleEnableForm }