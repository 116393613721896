const ShowMore = (()=>{

    const ShowMoreConfig = ()=>{
        const button = document.querySelector('#show-more');
        const showMoreContainer = document.querySelector('#show-more-container');

        button?.addEventListener('click', ()=>{
            showMoreContainer.classList.add('active');
        });
    }

    return {
        handleShowMore : function(){
            try { ShowMoreConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleShowMore = ()=>{
    ShowMore.handleShowMore();
}

export { handleShowMore }