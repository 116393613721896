//Reuse cls error and text content
const reUseError = (parent, msg, content)=>{
    parent.classList.remove('success-validation');
    parent.classList.add('error-validation');
    msg.textContent = content;
}
//Test exp, min and max
const testExp = (event)=>{
    const getValue = event.target.value;
    const getParent = event.target.closest('.block-input');
    const getMsgError = getParent.querySelector('.msg-error');
    const getMinLength = event.target.getAttribute('minlength');
    const getMaxLength = event.target.getAttribute('maxlength');
    const getDataExp = event.target.getAttribute('data-expresion');
    const getDataError = getMsgError.getAttribute('data-error');
    const newRegExp = new RegExp(getDataExp);
    //Validate minlength
    if(getValue.length < getMinLength){
        reUseError(getParent, getMsgError, `Ingresa mínimo ${getMinLength} caracteres`);
        return
    }
    //Validate maxLength
    if(getValue.length > getMaxLength){
        reUseError(getParent, getMsgError, `No se permiten más de ${getMaxLength} caracteres`);
        return
    }
    //Validate exp regular
    if(newRegExp.test(getValue)){
        getParent.classList.add('success-validation');
        getParent.classList.remove('error-validation');
    }else{
        reUseError(getParent, getMsgError, getDataError);
    }
}

const ValidateFields = ()=>{
    const getInputs = document.querySelectorAll('.form-general .block-input input');
    const getAllMsgError = document.querySelectorAll('.form-general .block-input .msg-error');
    //Add msg error
    getAllMsgError.forEach((error)=>{ error.textContent = error.getAttribute('data-error') })
    //Events
    getInputs.forEach((input)=>{
        const getType = input.getAttribute('type');
        if(getType == 'text' || getType == 'email' || getType == 'password'){
            input.addEventListener('blur', (e)=>{ testExp(e) });
            input.addEventListener('keyup', (e)=>{ 
                const getBlockValidation = input.closest('.block-input.error-validation');
                getBlockValidation && testExp(e);
            });
        }
    });
}

export { ValidateFields }