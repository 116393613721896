import { ValidateDate, ValidateAge } from '../components/index';

const RegisterForm = (()=>{

    const RegisterFormConfig = () => {
        ValidateDate('#register-form');
        ValidateDate('#register-form-tutor');
        //Vars
        const form = document.querySelector('#register-form');
        const formTutor = document.querySelector('#register-form-tutor');
        const modalConfirm = document.querySelector('#modal-register-success');
        const body = document.body;

        //Listener when submit form
        form?.addEventListener('submit', ()=>{
            //Validation fields
            if(form.dataset.token == '1asd123as1asdqeq'){
                //Validate age
                const age = ValidateAge(localStorage.getItem('full-date'));
                if(age){
                    modalConfirm.classList.add('active');
                }else{
                    formTutor.classList.remove('hidden');
                    form.classList.add('hidden');
                    body.classList.add('es-menor')
                }
            }
        });
        //Listener when submit form
        formTutor?.addEventListener('submit', ()=>{
            //Validation fields
            if(formTutor.dataset.token == '1asd123as1asdqeq'){
                modalConfirm.classList.add('active');
            }
        });
    }

    return {
        handleRegisterForm : function(){
            try { RegisterFormConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleRegisterForm = ()=>{
    RegisterForm.handleRegisterForm();
}

export { handleRegisterForm }