const Loader = (()=>{

    const LoaderConfig = ()=>{
        const loaderCounter = document.querySelector('.loader__counter');
        const loaderProgressBar = document.querySelector('.loader__progress span');
        const loader = document.querySelector('.loader');
        let counter = 0;
        let intervalTime = 500; // Tiempo inicial del intervalo
        
        let interval = setInterval(updateLoader, intervalTime);
        
        function updateLoader() {
            counter += 1;
            loaderProgressBar.style.width = `${counter}%`;
            loaderCounter.textContent = `${counter}`;
        
            if (counter === 100) {
                clearInterval(interval);
                loader.classList.add('hidden-loader');
            }
        }
        
        // Event when page load
        window.addEventListener('load', () => {
            clearInterval(interval); 
            intervalTime = 10;
            interval = setInterval(updateLoader, intervalTime); 
        });
    }

    return {
        handleLoader : function(){
            try { LoaderConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleLoader = ()=>{
    Loader.handleLoader();
}

export { handleLoader }