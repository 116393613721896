const ValidateCheckbox = ()=>{
    const getAllCheckbox = document.querySelectorAll('input[type="checkbox"]');
    const getAllRadios = document.querySelectorAll('input[type="radio"]');
    //Validate checkbox && radios
    const reUseValidations = (cls, type)=>{
        cls.forEach((data)=>{
            const reUseValidateChecked = ()=>{
                const getParent = data.closest('.block-input');
                if(type == 'checkbox'){
                    data.checked ? (data.classList.add('success-validation'), data.value = 1) : (data.classList.remove('success-validation'), data.value = 0);
                }else{
                    data.checked ? (data.classList.add('success-validation')) : (data.classList.remove('success-validation'));
                }
                //Validation for some inputs
                const getAllCheckboxValidate = getParent?.querySelectorAll('input.success-validation');
                getAllCheckboxValidate && getAllCheckboxValidate.length ? (
                    getParent && getParent.classList.remove('error-validation'),
                    getParent && getParent.classList.add('success-validation')
                ) : (
                    getParent && getParent.classList.add('error-validation'),
                    getParent && getParent.classList.remove('success-validation')
                );
            }
            data.addEventListener('change', reUseValidateChecked);
        });
    }

    reUseValidations(getAllCheckbox, 'checkbox');
    reUseValidations(getAllRadios, 'radio');
}

export { ValidateCheckbox }