const Select = (()=>{

    const SelectConfig = ()=>{
        const selectElement = document.querySelector('#edition');
        const imageElement = document.querySelector('#image-display');

        selectElement?.addEventListener('change', function() {
            const selectedOption = selectElement.options[selectElement.selectedIndex];
            const newImage = selectedOption.getAttribute('data-image');
            
            if (newImage) {
                imageElement.src = newImage;
            }
        });
    }

    return {
        handleSelect : function(){
            try { SelectConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleSelect = ()=>{
    Select.handleSelect();
}

export { handleSelect }