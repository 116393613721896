const HeaderTypeCurrency = () => {
    const headerCurrency = document.querySelector('#type-of-currency');
    const openHeaderCurrency = document.querySelectorAll('.open-type-of-currency');
    const closeHeaderCurrency = document.querySelector('#close-type-of-currency');

    openHeaderCurrency && openHeaderCurrency.forEach((close)=>{
        close.addEventListener('click', ()=>{
            headerCurrency.classList.add('active');
        }); 
    });

    closeHeaderCurrency.addEventListener('click', ()=>{
        headerCurrency.classList.remove('active');
    }); 
}

const HeaderHamburger = () => {
    const closeHamburgerMenu = document.querySelector('#close-hamburger-menu');
    const openHamburgerMenu = document.querySelector('#open-hamburger-menu');
    const hamburgerMenu = document.querySelector('#hamburger-menu');

    closeHamburgerMenu && closeHamburgerMenu.addEventListener('click', ()=>{
        hamburgerMenu?.classList.remove('active');
    });

    openHamburgerMenu && openHamburgerMenu.addEventListener('click', ()=>{
        hamburgerMenu?.classList.add('active');
    });
}

export { HeaderTypeCurrency, HeaderHamburger }