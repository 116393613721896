
const allowKeys = {
    "12": 12,
    "8": 8,
    "9": 9
}
//Push elements
for(let i = 33; i <= 57; i++){
    allowKeys[i] = i; 
}

//Push elements
for(let i = 95;i <= 105; i++){
    allowKeys[i] = i; 
}

//Remove numbers of input type text
const ValidateAllowKey = ()=>{
    const getAllInputsText = document.querySelectorAll('input[type="text"]');
    getAllInputsText.forEach((data)=>{
        if(!data.dataset.number){
            data.addEventListener('keydown', (e)=>{
                if(e.which >= 48 && e.which <= 57){
                    e.preventDefault();
                }
            });
        }
        if(data.dataset.text == 'disabled'){
            data.addEventListener('keydown', (e)=>{
                if(!allowKeys[e.which]){
                    e.preventDefault();
                }
            });
        }
    });
}



export { ValidateAllowKey }




