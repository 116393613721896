const Toggle = (()=>{

    const ToggleConfig = ()=>{
        const toggles = document.querySelectorAll('.toggle');

        toggles.forEach((toggle, index)=>{

            index == 0 && toggle.classList.add('active');

            toggle.addEventListener('click', ()=>{
                
                toggles.forEach((toggleNested)=>{
                    toggleNested.classList.remove('active');
                });

                toggle.classList.toggle('active');
            });
        });
    }

    return {
        handleToggle : function(){
            try { ToggleConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleToggle = ()=>{
    Toggle.handleToggle();
}

export { handleToggle }