//Validate Selects
import { ValidateSelect } from './ValidateSelect';
//Validate reserve words
import { ValidateReserveWord } from './ValidateReserveWord';
//Validate Checboxs
import { ValidateCheckbox } from './ValidateCheckbox';
//Validate Fields
import { ValidateFields } from './ValidateFields';
//Validate AllowKeys
import { ValidateAllowKey } from './ValidateAllowKeys';
//Validate Match
import { ValidateMatch } from './ValidateMatch';
//Show and hidden password
import { PasswordShowHidden } from './ValidatePassword';

const ValidateForm = (()=>{
    
    const SendForm = ()=>{
        //Selects
        ValidateSelect();
        //Reserve words
        ValidateReserveWord();
        //Checbkoxs
        ValidateCheckbox();
        //Fields
        ValidateFields();
        //Allow keys
        ValidateAllowKey();
        //match
        ValidateMatch();
        //Show and hidden password
        PasswordShowHidden();
        
        const getFormGeneral = document.querySelectorAll('.form-general');
        getFormGeneral.forEach((form)=>{
            form.addEventListener('submit', (e)=>{
                const getButton = form.querySelector('button');
                e.preventDefault();
                //Validate class valid
                const getAllBlocks = form.querySelectorAll('.block-input');
                getAllBlocks.forEach((block)=>{
                    !block.classList.contains('success-validation') && block.classList.add('error-validation');
                });
                //Focus firts input with error
                const getFirtsElementError = form.querySelector('.error-validation');
                const getInputError = getFirtsElementError && getFirtsElementError.querySelector('input');
                const getSelectError = getFirtsElementError && getFirtsElementError.querySelector('select');
                // Scroll to element with error
                if(getInputError){
                    getInputError && getInputError.focus();
                }else{
                    getSelectError && getSelectError.focus();
                }
                //Validate inputs wrong
                const getAllBlocksError = form.querySelectorAll('.block-input.error-validation');
                console.log(getAllBlocksError.length);
                getAllBlocksError.length == 0 ? 
                (form.setAttribute('data-token', '1asd123as1asdqeq'), getButton.classList.add('disabled')) : 
                form.setAttribute('data-token', '1asd123ass1asdqeq');
                    
            });
        });

    }

    //Return child functions 
    return {
        setHandleEvent: function(){
            try {
                SendForm();
            } catch (error) { console.log(error) }
        }
    }

})();

//Getting the child functions and saving them in a function
const ValidateFormHandleEvent = ()=>{
    ValidateForm.setHandleEvent();
}

export { ValidateFormHandleEvent }