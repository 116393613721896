const Filter = (()=>{

    const FilterConfig = ()=>{
        const filterButton = document.querySelectorAll('.filter-button');
        const filter = document.querySelector('#filter');

        filterButton.forEach((btn)=>{
            btn.addEventListener('click', ()=>{
                filter.classList.toggle('active');
            });
        })
    }

    return {
        handleFilter : function(){
            try { FilterConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleFilter = ()=>{
    Filter.handleFilter();
}

export { handleFilter }