const Tab = (()=>{


    const reUseAtributtes = (element, type, cls, navMobile) => {
        element.forEach((data, indice)=>{
            indice == 0 && data.classList.add(cls);
            indice == 0 && type == 'btn' && (
                navMobile && (navMobile.textContent = data.textContent)
            );
            data.setAttribute(`data-tab-${type}`, indice);
        });
    }

    const TabConfig = ()=>{
        const tabButton = document.querySelectorAll('.tab .tab__button');
        const tabContainer = document.querySelectorAll('.tab .tab__container');
        const tabNavMobile = document.querySelector('.tab .tab__nav--mobile');
        const tabButtonMobile = document.querySelector('.tab .tab__nav--mobile button');
        //Set atributtes and enable first button and ctn
        reUseAtributtes(tabButton, 'btn', 'btn--secondary', tabButtonMobile);
        reUseAtributtes(tabContainer, 'ctn', 'active');
        //Listener
        tabButton.forEach((button)=>{
            button.addEventListener('click', () => {
                const atrButton = button.getAttribute('data-tab-btn');
                //Mobile nav
                tabNavMobile?.nextElementSibling.classList.remove('active');
                tabButtonMobile.textContent = button.textContent;
                //Remove all active buttons
                tabButton.forEach((btn)=>{
                    btn.classList.remove('btn--secondary');
                });
                //Enable clicked btn
                button.classList.add('btn--secondary');
                //Compare atr and enable container
                tabContainer.forEach((ctn)=>{
                    const atrContainer = ctn.getAttribute('data-tab-ctn');
                    ctn.classList.remove('active');
                    atrContainer == atrButton && ctn.classList.add('active');
                });
            });
        });
        //Listener tab mobile
        tabNavMobile?.addEventListener('click', ()=>{
            const nextElementSibling = tabNavMobile.nextElementSibling;
            nextElementSibling && nextElementSibling.classList.add('active');
        }); 
    }

    return {
        handleTab : function(){
            try { TabConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleTab = ()=>{
    Tab.handleTab();
}

export { handleTab }