// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay, FreeMode, cssMode } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const SwiperLibrary = (()=>{

    const SwiperHomePage = ()=>{
        const swiper = new Swiper('.home-hero-swiper', {
            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade, Autoplay],
            spaceBetween: 30,
            effect: "fade",
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
        });

        const swiperFeatured = new Swiper('.home-featured-swiper', {
            // configure Swiper to use modules
            spaceBetween: 30,
            slidesPerView: 1.2,
            breakpoints: {
                568: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3.2,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 4.2,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                },
            }
        });

        const swiperCategories = new Swiper('.home-categories-swiper', {
            // configure Swiper to use modules
            modules: [Navigation],
            spaceBetween: 30,
            slidesPerView: 1.2,
            navigation: {
                nextEl: ".swiper-custom-button-next",
                prevEl: ".swiper-custom-button-prev",
            },
            breakpoints: {
                568: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3.2,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                },
            }
        });
    }

    const SwiperTestimonials = () =>{
        const swiper = new Swiper('.swiper-testimonials', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay],
            spaceBetween: 10,
            slidesPerView: 1,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    }

    const SwiperProfile = () =>{
        const reuseSwiper = (cls, reverse) => {
            const swiper = new Swiper(cls, {
                modules: [Autoplay, FreeMode],
                loop: true,
                freeMode: true,
                autoplay: {
                  delay: 0,
                  disableOnInteraction: false,
                  reverseDirection: reverse ? reverse : false,
                },
                speed: 5000,
                grabCursor: true,
                freeModeMomentum: true, // Habilitar inercia en free mode
                simulateTouch: false, // Desactiva el draggable
                spaceBetween: 20,
                slidesPerView: 1.2,
                loopAdditionalSlides: 1.2,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        loopAdditionalSlides: 2,
                    },
                    992: {
                        slidesPerView: 3,
                        loopAdditionalSlides: 3,
                    },
                    1200: {
                        slidesPerView: 'auto',
                        loopAdditionalSlides: 4,
                    },
                },
            });
        }

        reuseSwiper('.swiper-container-free-mode', false);
        reuseSwiper('.swiper-container-free-mode-reverse', true);
    }

    const SwiperNews = () => {
        const swiper = new Swiper('.swiper-news', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay],
            spaceBetween: 10,
            slidesPerView: 1,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    }


    return {
        handleSwiper : function(){
            try { SwiperHomePage(); } catch (error) { }
            try { SwiperTestimonials(); } catch (error) { }
            try { SwiperProfile(); } catch (error) { }
            try { SwiperNews(); } catch (error) { }
        }
    }

})();


const handleSwiper = ()=>{
    SwiperLibrary.handleSwiper();
}

export { handleSwiper }