
import "../sass/main.scss";
//Components
import { 
    HeaderTypeCurrency, 
    HeaderHamburger, 
    DarkLightMode, 
    handleSwiper, 
    handleTab ,
    handlePopup,
    handleLoader,
    handleFilterRange,
    handleFilter,
    handleToggle,
    handleSelect,
    handleShowMore,
    handleEnableForm,
    logingx,
    logeoGX,
    interacciones,
    interaccionesregister,
    registerGX,
    registertutorGX,
    actualizadatosformuno,
    registerLinksGX,
    actualizartutor,
    actualizarContrasena,
    actualizaravatar,
    interaccionesmodal,
    fondoswallet,
    eliminardecarrito
    //noticesajax
} from './components/index';
//Validation for forms
import { 
    ValidateFormHandleEvent,
 } from './formValidation/Index';
//Forms
import { 
    handleLoginForm,
    handleRegisterForm
} from './form/index';

window.addEventListener('load', ()=>{
    HeaderTypeCurrency();
    HeaderHamburger();
    DarkLightMode();
    handleSwiper();
    handleTab();
    handlePopup();
    ValidateFormHandleEvent();
    handleFilterRange();
    handleFilter();
    handleToggle();
    handleSelect();
    handleShowMore();
    handleEnableForm();
    //Forms
    handleLoginForm();
    handleRegisterForm();
    logingx();
    logeoGX();
    interacciones();
    interaccionesregister();
    registerGX();
    registertutorGX();
    actualizadatosformuno();
    registerLinksGX();
    actualizartutor();
    actualizarContrasena();
    actualizaravatar();
    interaccionesmodal();
    fondoswallet();
    eliminardecarrito();
});

window.addEventListener('DOMContentLoaded', ()=>{
    
    var templateUrl = object_name.templateUrl;

    
    var thecountrycurrency = document.getElementById('countrycurrency');
    var links = thecountrycurrency.querySelectorAll('a[rel]');
    links.forEach(function(link) {
        var currency = link.getAttribute('rel'); // Obtiene el valor del atributo 'rel'

        if (currency === 'COP') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagcolombia.svg" alt="Bandera GamerXperience"> COL';
        } else if (currency === 'USD') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagecuador.svg" alt="Bandera GamerXperience"> ECU';
        } else if (currency === 'PEN') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagperu.svg" alt="Bandera GamerXperience"> PER';
        } else if (currency === 'MXN') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagmexico.svg" alt="Bandera GamerXperience"> MEX';
        } else if (currency === 'CLP') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagchile.svg" alt="Bandera GamerXperience"> CHL';
        }
    })

    handleLoader();
});