//Validate selects
const ValidateSelect = ()=>{
    const getAllSelects = document.querySelectorAll('select');
    getAllSelects.forEach((data)=>{
        data.firstElementChild.disabled = true;
        const reUseValidateSelect = ()=>{
            const getParent = data.closest('.block-input');
            data.classList.add('change');
            getParent && getParent.classList.add('success-validation');
            getParent && getParent.classList.remove('error-validation');
        }
        data.addEventListener('change', reUseValidateSelect);
    });
}

export { ValidateSelect }