const reUseDarkLightMode = (button, secondaryButton, state) => {
    button?.addEventListener('click', ()=>{
        state ? document.body.classList.add('dark-mode') : document.body.classList.remove('dark-mode');
        button.classList.add('active');
        secondaryButton.classList.remove('active');
        localStorage.setItem('dark-mode', JSON.stringify(state));
    });
}

const DarkLightMode = () =>{
    const getStateDark = JSON.parse(localStorage.getItem('dark-mode')) || false;
    const darkModeButton = document.querySelector('#dark-mode');
    const lightModeButton = document.querySelector('#light-mode');
    const body = document.body;
    //Validate if exist option in local storage
    try{
        getStateDark ? (
            body?.classList.add('dark-mode'),
            lightModeButton?.classList.remove('active'),
            darkModeButton?.classList.add('active')
        ) : (
             body?.classList.remove('dark-mode'),
             lightModeButton?.classList.add('active'),
             darkModeButton?.classList.remove('active')
        )
    }catch(error){
        throw new Error('Error with validation storage');
    }
    //Events dark and light mode
    reUseDarkLightMode(darkModeButton, lightModeButton, true);
    reUseDarkLightMode(lightModeButton, darkModeButton, false);
}

export { DarkLightMode }