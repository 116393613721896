const FilterRange = (function () {
    const FilterRangePrice = () => {
        let setMinVal;
        let setMaxVal;

        const txtMinValue = document.getElementById('min-price-txt')
        const txtMaxValue = document.getElementById('max-price-txt')
        const rangeInput = document.querySelectorAll('.range-input input')
        const progress = document.querySelector('.slider .progress')
        let priceGap = 100000

        rangeInput.forEach((input) => {
            input.addEventListener('input', (e) => {
                let minVal = parseInt(rangeInput[0].value),
                    maxVal = parseInt(rangeInput[1].value)

                if (maxVal - minVal < priceGap) {
                    if (e.target.className === 'range-min') {
                        rangeInput[0].value = maxVal - priceGap
                    } else {
                        rangeInput[1].value = minVal + priceGap
                    }
                } else {
                    //imprimimos el valor de cada range en su span
                    txtMinValue.innerHTML = `$${formatNumber(minVal)}`
                    txtMaxValue.innerHTML = `$${formatNumber(maxVal)}`
                    setMinVal = `$${formatNumber(minVal)}`
                    setMaxVal = `$${formatNumber(maxVal)}`
                    progress.style.left =
                        (minVal / rangeInput[0].max) * 100 + '%';
                    progress.style.right =
                        100 - (maxVal / rangeInput[1].max) * 100 + '%';
                }
            });

        });

        function formatNumber(floatValue = 0, decimals = 0, multiplier = 1) {
            let floatMultiplied = floatValue * multiplier
            let stringFloat = floatMultiplied + ''
            let arraySplitFloat = stringFloat.split('.')
            let decimalsValue = '0'
            if (arraySplitFloat.length > 1) {
                decimalsValue = arraySplitFloat[1].slice(0, decimals)
            }
            let integerValue = arraySplitFloat[0]
            let arrayFullStringValue = [integerValue, decimalsValue]
            let FullStringValue = arrayFullStringValue.join('.')
            let floatFullValue = parseFloat(FullStringValue) + ''
            let formatFloatFullValue = new Intl.NumberFormat('es-ES', {
                minimumFractionDigits: decimals,
            }).format(floatFullValue)
            return formatFloatFullValue;
        }

    }

    return {
        handleFilterRange: function () {
            try {
                FilterRangePrice();
            } catch (error) {
                console.log(error);
            }
        },
    }
})()

const handleFilterRange = () => {
    FilterRange.handleFilterRange()
}

export { handleFilterRange }
