const ValidateMatch = ()=>{
    const getAllBlocksInput = document.querySelectorAll('.block-input');

    getAllBlocksInput.forEach((block)=>{
        if(block.dataset.match){
            const getInput = block.querySelector('input');
            const getInputMatch = document.querySelector(`#${block.dataset.match}`);
            const reUseMatch = (cls)=>{
                cls.addEventListener('blur', ()=>{
                    console.log('hola');
                    const value = getInput.value;
                    if(value == getInputMatch.value && value.length > 5){
                        block.classList.add('success-validation');
                        block.classList.remove('error-validation');
                    }else{
                        block.classList.remove('success-validation');
                        block.classList.add('error-validation');
                    }
                });
            }
            reUseMatch(getInput);
            reUseMatch(getInputMatch);
        }
    });
}

export { ValidateMatch }