const ValidatePassword = ()=>{
}


//Show and hidden pass
const PasswordShowHidden = ()=>{
    const getAllBtnEyes = document.querySelectorAll('.toggle-password');

    getAllBtnEyes.forEach((btn)=>{
        btn.addEventListener('click', ()=>{
            btn.classList.toggle('active');
            const getInput = btn.closest('.block-input').querySelector('input');

            btn.classList.contains('active') ? 
                (btn.src = '../img/svg/eye-line.svg', getInput.type = 'text') : 
                (btn.src = '../img/svg/eye.svg', getInput.type = 'password');
        });
    });
}


export { PasswordShowHidden }