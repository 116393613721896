const Popup = (()=>{

    const PopupConfig = ()=>{
        const closePopup = document.querySelectorAll('.popup-close-event');

        closePopup.forEach((close)=>{
            close.addEventListener('click', (e)=>{
                const parent = e.target.closest('.popup');
                parent && parent.classList.remove('active');
            });
        });
    }

    return {
        handlePopup : function(){
            try { PopupConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handlePopup = ()=>{
    Popup.handlePopup();
}

export { handlePopup }