const reserveWords = [ 'INSERT', 'UPDATE', 'SELECT', 'DELETE', 'WHERE', 'where', 'UNION', 'union', 'SLEEP', 'sleep', 'img', '<img>', 'src', '<div>', '<html>', 'FROM', 'insert', 'update', 'select', 'delete', 'from', '<HTML>', 'error', 'onerror', 'prompt', 'ERROR', 'PROMPT', 'EXTRACTVALUE', 'extractvalue', 'CONCAT', 'concat', 'DBMS', 'dbms', 'MESSAGE', 'message', 'ORDER', 'order'];

const ValidateReserveWord = ()=>{
    const getAllInputs = document.querySelectorAll('input');
    const getBtnSendForm = document.querySelectorAll('button');
    //Function for reset input when find a reserve word
    const reUseRemoveReserveWords = (cls,typeEvent)=>{
        cls.forEach((data)=>{
            data.addEventListener(typeEvent, ()=>{
                reserveWords.forEach((dataArr)=>{
                    data.value.includes(dataArr) && (data.value = '');
                });
            });
        });
    }

    reUseRemoveReserveWords(getAllInputs, 'keyup');
    reUseRemoveReserveWords(getBtnSendForm, 'click');

}

export { ValidateReserveWord }