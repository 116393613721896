//Validate date
const ValidateDate = (form)=>{
    const reUseValidateDate = (dateString)=> {
        let parts = dateString.split("-");
        let day = parseInt(parts[2], 10);
        let month = parseInt(parts[1], 10) - 1; // Los meses en JavaScript van de 0 a 11
        let year = parseInt(parts[0], 10);
        let createDate = new Date(year, month, day);
        //Validate all parameters
        return (
            createDate.getDate() === day &&
            createDate.getMonth() === month &&
            createDate.getFullYear() === year
        );
    }
    //Get selects date
    const getDay = document.querySelector(`${form} #day`);
    const getMonth = document.querySelector(`${form} #month`);
    const getYear = document.querySelector(`${form} #year`);
    const getAllSelects = document.querySelectorAll(`${form} #register-date select`);
    let completeDate;
    //Reuse event change
    const ReUseEvent = (select)=>{
        select?.addEventListener('change', ()=>{
            completeDate = `${getYear.value}-${getMonth.value}-${getDay.value}`;
            if(getDay.value != '' && getMonth.value != '' && getYear.value != ''){
                const getReturn = reUseValidateDate(completeDate);
                if(getReturn != true){
                    getAllSelects.forEach((data)=>{
                        setTimeout(()=>{
                            data.closest('.block-input')?.classList.add('error-validation');
                            data.closest('.block-input')?.classList.remove('success-validation');
                            data.value = ''; 
                        },100);
                    });
                }else{
                    localStorage.setItem('full-date', completeDate);
                }
            }
        });
    }
    ReUseEvent(getDay);
    ReUseEvent(getMonth);
    ReUseEvent(getYear);
}


const ValidateAge = (date)=>{
    const birthdate = new Date(date);    
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthdate.getFullYear();
    
    const mes = currentDate.getMonth() - birthdate.getMonth();
    if (mes < 0 || (mes === 0 && currentDate.getDate() < birthdate.getDate())) {
        age--;
    }
    
    return age >= 18;
}

export { ValidateDate, ValidateAge };